<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="門市名稱" prop="name" align="center" />
      <BaseElTableColumn label="連結" prop="link" align="center">
        <template slot-scope="scope">
          <div class="flex items-center" style="gap: 12.5px">
            <p>{{ scope.row.link }}</p>
            <span
              class="material-icons text-primary-100 cursor-pointer block"
              style="width: 24px; transform: scaleX(-1)"
              @click="copyLink(scope.row.link)"
            >
              content_copy_outlined
            </span>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="Qrcode" fixed="right" align="center">
        <template slot-scope="scope">
          <BaseElButton class="underline text-primary-100" type="text" @click="onDownloadQrcode(scope.row)">
            下載
          </BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { FindSalesClientLink } from '@/api/sales'
import { get, map } from 'lodash'
import copy from 'clipboard-copy'
import QRCode from 'qrcode'

export default defineComponent({
  name: 'DataTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useTable()
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, i => {
        return {
          id: i.id,
          name: get(i, 'Branch.name'),
          link: i.shortUrl,
        }
      })
    })

    const onDownloadQrcode = async (row) => {
      const [res, err] = await FindSalesClientLink({
        shopId: shopId.value,
        id: row.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }

      const generateQR = async (text) => {
        try {
          return await QRCode.toDataURL(text)
        } catch (err) {
          window.$message.error(`生成 Qrcode 錯誤：${err}`)
          console.error(err)
        }
      }

      const qrcodeRes = await generateQR(res.shortUrl)
      downloadURI(qrcodeRes, `${get(res, 'Branch.name')}-qrcode.png`)
    }

    const copyLink = (link) => {
      copy(link)
      window.$message.success('複製連結成功！')
    }

    const downloadURI = (uri, name) => {
      const link = document.createElement('a')
      link.download = name
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // delete link
    }

    return {
      modal,
      displayData,
      onDownloadQrcode,
      copyLink,
    }
  },
})
</script>
